import create from "zustand";
import { devtools, persist } from 'zustand/middleware'

let form = (set) => ({
    name: '',
    setName: (value) => {
        set(() => {
            return {
                name: value
            }
        })
    },
    nrp: '',
    setNrp: (value) => {
        set(() => {
            return{
                nrp: value
            }
        })
    },
    date: '',
    setDate: (value) => {
        set(() => {
            return {
                date: value
            }
        })
    },
    corp: '',
    setCorp: (value) => {
        set(() => {
            return {
                corp: value
            }
        })
    },
    date_in: '',
    setDateIn: (value) => {
        set(() => {
            return {
                date_in: value
            }
        })
    },
    status: '',
    setStatus: (value) => {
        set(() => {
            return {
                status: value
            }
        })
    },
    pos: '',
    setPos: (value) => {
        set(() => {
            return {
                pos: value
            }
        })
    }
})

form = devtools(form)
// form = persist(form)

const useFormStore = create(form)

export default useFormStore