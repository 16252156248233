import React from "react";
import { Link } from "react-router-dom";
import "./LandingPage.css";
import logo from "../../assets/ykbut.png"
import { useNavigate } from 'react-router-dom'

const LandingPage = () => {
  let navigate = useNavigate();
  return (
    <div className="landingpage">
      <div className="mainContainer">
        <img src={logo} alt="" />
        <p>Welcome To Employee Engagement Survey</p>
        <div className="gapBtnLanding">
        {/*<button className="btnLanding" onClick={() => navigate('/Welcome')}>
          Start
        </button>*/}
        <button className="btnLanding">
          Close
        </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
