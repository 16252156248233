import React from 'react'
import './Selection.css'
import logo from "../../assets/ykbut.png"
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const SelectionCustomer = () => {
  let navigate = useNavigate();
  return (
    <div className="landingpage">
      <div className="selectionContainer">
        <img src={logo} alt="" />
        <p>Pilih Tempat Kerja Anda!</p>
        <div className="gapBtnSelection">
          <button className="btnSelection" onClick={() => navigate('/FormFront')}>
            Site/Cabang
          </button>
          <button className="btnSelection" onClick={() => navigate('/FormFront')}>
            Head Office
          </button>
        </div>
      </div>
    </div>
  )
}

export default SelectionCustomer