import React from 'react'
import logo from '../../assets/ykbut.png'
import './thankyoupage.css'

const Thankyoupage = () => {
  return (
    <div className='mainContainer'>
        <img src={logo} alt="" />
        <p>Terima kasih telah mengisi survey</p>
    </div>
  )
}

export default Thankyoupage