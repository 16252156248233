import logo from './logo.svg';
import './App.css';
import {
  LandingPage,
  Selection,
  QuestPage,
  Thankyoupage,
  FormFront,
  QuestPageFront,
  Welcome,
  Form,
  SelectionCustomer,
  SelectionInternal
} from './views'
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/Welcome" element={<Welcome />} />
          <Route path="/Selection" element={<Selection />} />
          <Route path="/SelectionCustomer" element={<SelectionCustomer />} />
          <Route path="/SelectionInternal" element={<SelectionInternal />} />
          <Route path="/FormFront" element={<FormFront />} />
          <Route path="/FormBack" element={<Form />} />
          <Route path="/QuestFront" element={<QuestPageFront />} />
          <Route path="/QuestBack" element={<QuestPage />} />
          <Route path="/Thankyou" element={<Thankyoupage/>} />
        </Routes>
      </BrowserRouter>

      {/* <Router>
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route exact path='/selection' component={Selection} />
          <Route exact path='/Form' component={Form} />
          <Route exact path='/Quest' component={QuestPage} />
          <Route exact path='/questionpage' component={questionpage}/>
          <Route exact path='/thankyoupage' component={thankyoupage}/>
          <Route exact path='/Frontliner' component={FormFront} />
          <Route exact path='/QuestFrontliner' component={QuestPageFront} />
          <Route exact path='/Welcome' component={Welcome} />
        </Switch> */}
      {/* <Redirect to="/Landing/" /> */}
      {/* </Router> */}
    </div>
  );
}

export default App;
