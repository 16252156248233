import React, { useState, useEffect } from "react";
import "./Form.css";
import bgImg from "../../assets/bg_form.png";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { MenuItem } from "@mui/material";
import useFormStore from "../../store/state";
import { useNavigate } from "react-router-dom";

export default function Form() {
  const [value, setValue] = useState("");
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");

  const [focus, setFocused] = useState(false);
  const [focus1, setFocused1] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const [hasValue1, setHasValue1] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const onFocus1 = () => setFocused1(true);
  const onBlur1 = () => setFocused1(false);
  const [showJabatan, setShowJabatan] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);
  const [dataForm, setDataForm] = useState({});
  const [dataNrp, setDataNrp] = useState([]);
  const { name, setName } = useFormStore((state) => state);
  const { nrp, setNrp } = useFormStore((state) => state);
  const { date, setDate } = useFormStore((state) => state);
  const { corp, setCorp } = useFormStore((state) => state);
  const { date_in, setDateIn } = useFormStore((state) => state);
  const { status, setStatus } = useFormStore((state) => state);
  const { pos, setPos } = useFormStore((state) => state);

  const [submenu, setSubmenu] = useState(false);
  const [submenuSenior, setSenior] = useState(false);
  const [subOfficer, setOfficer] = useState(false);
  const [subStaff, setStaff] = useState(false);
  const [subLeader, setLeader] = useState(false);
  const [subHead, setHead] = useState(false);
  const [showPositions, setShowPositions] = useState(true);

  let navigate = useNavigate();

  // useEffect(() => {
  //   async function fetchData() {
  //     // Ganti alamat IP dengan alamat domain yang sesuai
  //     // const domainURL = "https://ees.ykbut.co.id/api/get/nrp/";
  //     const domainURL = "https://ees.ykbut.co.id/api/get/nrp/";
  //     const response = await fetch(domainURL);
  //     const data = await response.json();
  //     if (data !== null) {
  //       setDataNrp(data.result);
  //     }
  //   }
  //   fetchData();
  // }, []);  

  const handleChange = (e) => {
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    });
  };

  const btnSubmit = (event) => {
    event.preventDefault();
  };

  const handleClick = (event) => {
    event.preventDefault();
    navigate("/QuestBack");
  };

  return (
    <section>
      <div className="register">
        <div className="col-1">
          <span>Form Registrasi Karyawan</span>

          <form id="form" className="flex flex-col" onSubmit={btnSubmit}>
            {/* <TextField
              id="outlined-basic"
              label="Nama"
              variant="outlined"
              type="text"
              name="name"
              required
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setName(e.target.value)}
            /> */}
            {/* <TextField
              id="outlined-basic"
              label="NRP"
              variant="outlined"
              type="number"
              name="nrp"
              required
              InputLabelProps={{ shrink: true }}
              onChange={(e) => (handleChange(e), setNrp(e.target.value))}
            /> */}
            <TextField
              InputProps={{
                classes: {
                  input: "CustomTextField",
                },
                inputProps: {
                  pattern: "\\d{4}",
                  maxLength: 4,
                },
              }}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                if (e.target.value) {
                  setHasValue(true);
                  setDate(e.target.value);
                } else {
                  setHasValue(false);
                  setDate(e.target.value);
                }
              }}
              label="Tahun Lahir"
              name="date"
              required
              // type="date"
            />
            <TextField
              sx={{ textAlign: "left" }}
              variant="outlined"
              value={value}
              onChange={(e) => (
                setValue(e.target.value), setCorp(e.target.value)
              )}
              select
              required
              InputLabelProps={{ shrink: true }}
              label="Perusahaan"
              name="corp"
            >
              <MenuItem key={1} value={791}>
                YKBUT
              </MenuItem>
              <MenuItem
                key={2}
                value={794}
                onClick={() => setShowJabatan(false)}
              >
                MBUT
              </MenuItem>
              <MenuItem
                key={3}
                value={795}
                onClick={() => setShowJabatan(false)}
              >
                GSI
              </MenuItem>
              <MenuItem
                key={4}
                value={789}
                onClick={() => setShowJabatan(false)}
              >
                KAMAJU
              </MenuItem>
              <MenuItem
                key={5}
                value={797}
                onClick={() => setShowJabatan(false)}
              >
                KOPERASI UT
              </MenuItem>
              <MenuItem
                key={6}
                value={788}
                onClick={() => setShowJabatan(false)}
              >
                DPP
              </MenuItem>
              <MenuItem
                key={7}
                value={796}
                onClick={() => setShowJabatan(false)}
              >
                LSP ABI
              </MenuItem>
              <MenuItem
                key={8}
                value={1360}
                onClick={() => setShowJabatan(false)}
              >
                KMS
              </MenuItem>
            </TextField>
            <TextField
              sx={{ textAlign: "left" }}
              variant="outlined"
              value={value3}
              onChange={(e) => (
                setValue3(e.target.value), setDateIn(e.target.value)
              )}
              select
              required
              InputLabelProps={{ shrink: true }}
              max
              label="Tahun Masuk"
              name="date_in"
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 270,
                    },
                  },
                },
              }}
            >
              <MenuItem value={1333}>2000</MenuItem>
              <MenuItem value={1334}>2001</MenuItem>
              <MenuItem value={1335}>2002</MenuItem>
              <MenuItem value={1336}>2003</MenuItem>
              <MenuItem value={1337}>2004</MenuItem>
              <MenuItem value={1338}>2005</MenuItem>
              <MenuItem value={1339}>2006</MenuItem>
              <MenuItem value={1340}>2007</MenuItem>
              <MenuItem value={1341}>2008</MenuItem>
              <MenuItem value={1342}>2009</MenuItem>
              <MenuItem value={1343}>2010</MenuItem>
              <MenuItem value={1344}>2011</MenuItem>
              <MenuItem value={1345}>2012</MenuItem>
              <MenuItem value={1346}>2013</MenuItem>
              <MenuItem value={1347}>2014</MenuItem>
              <MenuItem value={1348}>2015</MenuItem>
              <MenuItem value={1349}>2016</MenuItem>
              <MenuItem value={1350}>2017</MenuItem>
              <MenuItem value={1351}>2018</MenuItem>
              <MenuItem value={1352}>2019</MenuItem>
              <MenuItem value={1353}>2020</MenuItem>
              <MenuItem value={1354}>2021</MenuItem>
              <MenuItem value={1355}>2022</MenuItem>
              <MenuItem value={1359}>2023</MenuItem>
            </TextField>
            <TextField
              sx={{ textAlign: "left" }}
              variant="outlined"
              value={value1}
              onChange={(e) => (
                setValue1(e.target.value), setStatus(e.target.value)
              )}
              select
              InputLabelProps={{ shrink: true }}
              label="Status Karyawan"
              required
              name="status"
            >
              <MenuItem key={1} value={799}>
                Tetap
              </MenuItem>
              <MenuItem key={2} value={800}>
                Kontrak
              </MenuItem>
            </TextField>
            <TextField
              sx={{ textAlign: "left" }}
              variant="outlined"
              value={value2}
              onChange={(e) => (
                setValue2(e.target.value), setPos(e.target.value)
              )}
              select
              InputLabelProps={{ shrink: true }}
              label="Jabatan"
              required
              name="pos"
              disabled={!showPositions}
              SelectProps={{
                MenuProps: {
                  variant: "menu",
                },
              }}
            >
              {/* Opsi Jabatan untuk YKBUT */}
              {value === 791 && showPositions
              ? [
                <MenuItem key={1} value={801}>
                Admin Junior
                </MenuItem>,
                <MenuItem key={2} value={802}>
                  Admin Senior
                </MenuItem>,
                <MenuItem key={3} value={805}>
                  Officer
                </MenuItem>,
                <MenuItem key={4} value={804}>
                  Staff
                </MenuItem>,
                <MenuItem key={6} value={1356}>
                  Head
                </MenuItem>
                ]
              : null}

              {/* Opsi Jabatan untuk MBUT */}
              {value === 794 && showPositions
              ? [
                <MenuItem key={1} value={801}>
                Admin Junior
                </MenuItem>,
                <MenuItem key={2} value={802}>
                  Admin Senior
                </MenuItem>,
                <MenuItem key={3} value={805}>
                  Officer
                </MenuItem>,
                <MenuItem key={4} value={804}>
                  Staff
                </MenuItem>,
                <MenuItem key={5} value={803}>
                  Leader & Staff Ahli
                </MenuItem>,
                <MenuItem key={6} value={1356}>
                  Head
                </MenuItem>
                ]
              : null}

              {/* Opsi Jabatan untuk GSI */}
              {value === 795 && showPositions
              ? [
                <MenuItem key={1} value={801}>
                Admin Junior
                </MenuItem>,
                <MenuItem key={2} value={802}>
                  Admin Senior
                </MenuItem>,
                <MenuItem key={3} value={805}>
                  Officer
                </MenuItem>,
                <MenuItem key={4} value={804}>
                  Staff
                </MenuItem>,
                <MenuItem key={5} value={803}>
                  Leader & Staff Ahli
                </MenuItem>,
                <MenuItem key={6} value={1356}>
                  Head
                </MenuItem>
                ]
              : null}

              {/* Opsi Jabatan untuk KAMAJU */}
              {value === 789 && showPositions
              ? [
                <MenuItem key={1} value={801}>
                Admin Junior
                </MenuItem>,
                <MenuItem key={2} value={802}>
                  Admin Senior
                </MenuItem>,
                <MenuItem key={3} value={805}>
                  Officer
                </MenuItem>,
                <MenuItem key={4} value={804}>
                  Staff
                </MenuItem>,
                <MenuItem key={5} value={803}>
                  Leader & Staff Ahli
                </MenuItem>,
                <MenuItem key={6} value={1356}>
                  Head
                </MenuItem>
                ]
              : null}

              {/* Opsi Jabatan untuk KOPERASI UT */}
              {value === 797 && showPositions
              ? [
                <MenuItem key={1} value={801}>
                Admin Junior
                </MenuItem>,
                <MenuItem key={2} value={802}>
                  Admin Senior
                </MenuItem>,
                <MenuItem key={3} value={805}>
                  Officer
                </MenuItem>
                ]
              : null}

              {/* Opsi Jabatan untuk DPP */}
              {value === 788 && showPositions
              ? [
                <MenuItem key={1} value={801}>
                Admin Junior
                </MenuItem>,
                <MenuItem key={2} value={802}>
                  Admin Senior
                </MenuItem>,
                <MenuItem key={3} value={805}>
                  Officer
                </MenuItem>,
                <MenuItem key={4} value={804}>
                  Staff
                </MenuItem>,
                <MenuItem key={5} value={803}>
                  Leader & Staff Ahli
                </MenuItem>,
                <MenuItem key={6} value={1356}>
                  Head
                </MenuItem>
                ]
              : null}

              {/* Opsi Jabatan untuk LSP ABI */}
              {value === 796 && showPositions
              ? [
                <MenuItem key={1} value={801}>
                Admin Junior
                </MenuItem>,
                <MenuItem key={2} value={802}>
                  Admin Senior
                </MenuItem>,
                <MenuItem key={3} value={805}>
                  Officer
                </MenuItem>,
                <MenuItem key={4} value={804}>
                  Staff
                </MenuItem>,
                <MenuItem key={5} value={803}>
                  Leader & Staff Ahli
                </MenuItem>,
                <MenuItem key={6} value={1356}>
                  Head
                </MenuItem>
                ]
              : null}

              {/* Opsi Jabatan untuk KMS */}
              {value === 1360 && showPositions
              ? [
                <MenuItem key={1} value={801}>
                Admin
                </MenuItem>,
                <MenuItem key={3} value={805}>
                  Officer
                </MenuItem>,
                <MenuItem key={4} value={804}>
                  Staff
                </MenuItem>,
                <MenuItem key={6} value={1356}>
                  Head
                </MenuItem>
                ]
              : null}

              {/* Opsi Jabatan default
              {value === 791 && value === 794 && value === 795 && value === 789 && value === 797 && value === 788 && value === 796 && value === 1360 && showPositions
              ? [
                  <MenuItem key={1} value={801}>
                    Admin Junior
                  </MenuItem>,
                  <MenuItem key={2} value={802}>
                    Admin Senior
                  </MenuItem>,
                  <MenuItem key={3} value={805}>
                    Officer
                  </MenuItem>,
                  <MenuItem key={4} value={804}>
                    Staff
                  </MenuItem>,
                  <MenuItem key={5} value={803}>
                    Leader & Staff Ahli
                  </MenuItem>,
                  <MenuItem key={6} value={1356}>
                    Head
                  </MenuItem>
                ]
              : null} */}

            </TextField>
            {errors.mobile?.type === "required" && "Mobile Number is required"}
            {errors.mobile?.type === "maxLength" && "Max Length Exceed"}
            <button className="btn" type="submit" onClick={handleClick}>
              Continue
            </button>
          </form>
        </div>
        <div className="col-2">
          <img src={bgImg} alt="" />
        </div>
        <div></div>
      </div>
    </section>
  );
}
