import React, { useState, useEffect } from "react";
import "./Form.css";
import bgImg from "../../assets/bg_form.png";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { MenuItem } from "@mui/material";
import useFormStore from "../../store/state";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const FormFront = () => {
  const [value, setValue] = useState("");
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");

  const [focus, setFocused] = useState(false);
  const [focus1, setFocused1] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const [hasValue1, setHasValue1] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const onFocus1 = () => setFocused1(true);
  const onBlur1 = () => setFocused1(false);
  
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);
  
  const [dataForm, setDataForm] = useState({});
  const [dataNrp, setDataNrp] = useState([]);
  const { name, setName } = useFormStore((state) => state);
  const { nrp, setNrp } = useFormStore((state) => state);
  const { date, setDate } = useFormStore((state) => state);
  const { corp, setCorp } = useFormStore((state) => state);
  const { date_in, setDateIn } = useFormStore((state) => state);
  const { status, setStatus } = useFormStore((state) => state);
  const { pos, setPos } = useFormStore((state) => state);

  const [submenu, setSubmenu] = useState(false);
  const [submenuSenior, setSenior] = useState(false);
  const [subOfficer, setOfficer] = useState(false);
  const [subStaff, setStaff] = useState(false);
  const [subLeader, setLeader] = useState(false);
  const [subHead, setHead] = useState(false);
  const [showJabatan, setShowJabatan] = useState(true);
  const [showPositions, setShowPositions] = useState(true);

  let navigate = useNavigate();

  // useEffect(() => {
  //   async function fetchData() {
  //     const response = await fetch(
  //       "https://ees.ykbut.co.id/api/get/nrp/front"
  //     );
  //     const data = await response.json();
  //     if (data !== null) {
  //       setDataNrp(data);
  //     }
  //   }
  //   fetchData();
  // }, []);

  const handleChange = (e) => {
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    });
  };

  const btnSubmit = (event) => {
    event.preventDefault();
  };

  const handleClick = (event) => {
    event.preventDefault();
    navigate("/QuestFront");
  };

  return (
    <section>
      <div className="register">
        <div className="col-1">
          <span>Form Registrasi Karyawan</span>

          <form id="form" className="flex flex-col" onSubmit={btnSubmit}>
            {/* <TextField
              id="outlined-basic"
              label="Nama"
              variant="outlined"
              type="text"
              name="name"
              required
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setName(e.target.value)}
            /> */}
            {/* <TextField
              id="outlined-basic"
              label="NRP"
              variant="outlined"
              type="number"
              name="nrp"
              required
              InputLabelProps={{ shrink: true }}
              onChange={(e) => (handleChange(e), setNrp(e.target.value))}
            /> */}
            <TextField
              InputProps={{
                classes: {
                  input: "CustomTextField",
                },
                inputProps: {
                  pattern: "\\d{4}",
                  maxLength: 4,
                },
              }}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                if (e.target.value) {
                  setHasValue(true);
                  setDate(e.target.value);
                } else {
                  setHasValue(false);
                  setDate(e.target.value);
                }
              }}
              label="Tahun Lahir"
              name="date"
              required
              // type="date"
            />
            <TextField
              sx={{ textAlign: "left" }}
              variant="outlined"
              value={value}
              onChange={(e) => (
                setValue(e.target.value), setCorp(e.target.value)
              )}
              select
              required
              InputLabelProps={{ shrink: true }}
              label="Perusahaan"
              name="corp"
            >
              <MenuItem key={1} value={569}>
                YKBUT
              </MenuItem>
              <MenuItem
                key={2}
                value={572}
                onClick={() => setShowJabatan(false)}
              >
                MBUT
              </MenuItem>
              <MenuItem
                key={3}
                value={573}
                onClick={() => setShowJabatan(false)}
              >
                GSI
              </MenuItem>
              <MenuItem
                key={4}
                value={567}
                onClick={() => setShowJabatan(false)}
              >
                KAMAJU
              </MenuItem>
              <MenuItem
                key={5}
                value={575}
                onClick={() => setShowJabatan(false)}
              >
                KOPERASI UT
              </MenuItem>
              <MenuItem
                key={6}
                value={566}
                onClick={() => setShowJabatan(false)}
              >
                DPP
              </MenuItem>
              <MenuItem
                key={7}
                value={574}
                onClick={() => setShowJabatan(false)}
              >
                LSP ABI
              </MenuItem>
              <MenuItem
                key={8}
                value={1361}
                onClick={() => setShowJabatan(false)}
              >
                KMS
              </MenuItem>
            </TextField>
            <TextField
              sx={{ textAlign: "left" }}
              variant="outlined"
              value={value2}
              onChange={(e) => (
                setValue2(e.target.value), setDateIn(e.target.value)
              )}
              select
              required
              max
              InputLabelProps={{ shrink: true }}
              label="Tahun Masuk"
              name="date_in"
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 270,
                    },
                  },
                },
              }}
            >
              <MenuItem value={1300}>2000</MenuItem>
              <MenuItem value={1301}>2001</MenuItem>
              <MenuItem value={1302}>2002</MenuItem>
              <MenuItem value={1303}>2003</MenuItem>
              <MenuItem value={1304}>2004</MenuItem>
              <MenuItem value={1305}>2005</MenuItem>
              <MenuItem value={1306}>2006</MenuItem>
              <MenuItem value={1307}>2007</MenuItem>
              <MenuItem value={1308}>2008</MenuItem>
              <MenuItem value={1309}>2009</MenuItem>
              <MenuItem value={1310}>2010</MenuItem>
              <MenuItem value={1311}>2011</MenuItem>
              <MenuItem value={1312}>2012</MenuItem>
              <MenuItem value={1313}>2013</MenuItem>
              <MenuItem value={1314}>2014</MenuItem>
              <MenuItem value={1315}>2015</MenuItem>
              <MenuItem value={1316}>2016</MenuItem>
              <MenuItem value={1317}>2017</MenuItem>
              <MenuItem value={1318}>2018</MenuItem>
              <MenuItem value={1319}>2019</MenuItem>
              <MenuItem value={1320}>2020</MenuItem>
              <MenuItem value={1321}>2021</MenuItem>
              <MenuItem value={1322}>2022</MenuItem>
              <MenuItem value={1358}>2023</MenuItem>
            </TextField>
            <TextField
              sx={{ textAlign: "left" }}
              variant="outlined"
              value={value1}
              onChange={(e) => (
                setValue1(e.target.value), setStatus(e.target.value)
              )}
              select
              label="Status Karyawan"
              required
              InputLabelProps={{ shrink: true }}
              name="status"
            >
              <MenuItem key={1} value={577}>
                Tetap
              </MenuItem>
              <MenuItem key={2} value={578}>
                Kontrak
              </MenuItem>
            </TextField>
            <TextField
              sx={{ textAlign: "left" }}
              variant="outlined"
              value={value3}
              onChange={(e) => (
                setValue3(e.target.value), setPos(e.target.value)
              )}
              select
              InputLabelProps={{ shrink: true }}
              label="Jabatan"
              required
              name="pos"
              disabled={!showPositions}
              SelectProps={{
                MenuProps: {
                  variant: "menu",
                },
              }}
            >
              {/* Opsi Jabatan untuk YKBUT */}
              {value === 569 && showPositions
              ? [
                <MenuItem key={1} value={579}>
                Admin Junior
                </MenuItem>,
                <MenuItem key={2} value={580}>
                  Admin Senior
                </MenuItem>,
                <MenuItem key={4} value={582}>
                  Staff
                </MenuItem>,
                <MenuItem key={5} value={581}>
                  Leader & Staff Ahli
                </MenuItem>
                ]
              : null}

              {/* Opsi Jabatan untuk MBUT */}
              {value === 572 && showPositions
              ? [
                <MenuItem key={1} value={579}>
                Admin Junior
                </MenuItem>,
                <MenuItem key={2} value={580}>
                  Admin Senior
                </MenuItem>
                ]
              : null}

              {/* Opsi Jabatan untuk GSI */}
              {value === 573 && showPositions
              ? [
                <MenuItem key={1} value={579}>
                Admin Junior
                </MenuItem>,
                <MenuItem key={2} value={580}>
                  Admin Senior
                </MenuItem>,
                <MenuItem key={3} value={583}>
                  Officer
                </MenuItem>,
                <MenuItem key={4} value={582}>
                  Staff
                </MenuItem>,
                <MenuItem key={5} value={581}>
                  Leader & Staff Ahli
                </MenuItem>
                ]
              : null}

              {/* Opsi Jabatan untuk KAMAJU */}
              {value === 567 && showPositions
              ? [
                <MenuItem key={1} value={579}>
                Admin Junior
                </MenuItem>,
                <MenuItem key={2} value={580}>
                  Admin Senior
                </MenuItem>,
                <MenuItem key={3} value={583}>
                  Officer
                </MenuItem>
                ]
              : null}

              {/* Opsi Jabatan untuk KOPERASI UT */}
              {value === 575 && showPositions
              ? [
                // <MenuItem key={1} value={579}>
                // Admin Junior
                // </MenuItem>,
                // <MenuItem key={2} value={580}>
                //   Admin Senior
                // </MenuItem>,
                // <MenuItem key={3} value={583}>
                //   Officer
                // </MenuItem>,
                // <MenuItem key={4} value={582}>
                //   Staff
                // </MenuItem>,
                // <MenuItem key={5} value={581}>
                //   Leader & Staff Ahli
                // </MenuItem>,
                // <MenuItem key={6} value={1357}>
                //   Head
                // </MenuItem>
                ]
              : null}

              {/* Opsi Jabatan untuk DPP */}
              {value === 566 && showPositions
              ? [
                <MenuItem key={1} value={579}>
                Admin Junior
                </MenuItem>,
                <MenuItem key={2} value={580}>
                  Admin Senior
                </MenuItem>,
                <MenuItem key={3} value={583}>
                  Officer
                </MenuItem>,
                <MenuItem key={4} value={582}>
                  Staff
                </MenuItem>,
                <MenuItem key={5} value={581}>
                  Leader & Staff Ahli
                </MenuItem>
                ]
              : null}

              {/* Opsi Jabatan untuk LSP ABI */}
              {value === 574 && showPositions
              ? [
                <MenuItem key={3} value={583}>
                  Officer
                </MenuItem>
                ]
              : null}

              {/* Opsi Jabatan untuk KMS */}
              {value === 1361 && showPositions
              ? [
                <MenuItem key={1} value={579}>
                Admin Junior
                </MenuItem>,
                <MenuItem key={2} value={580}>
                  Admin Senior
                </MenuItem>,
                <MenuItem key={4} value={582}>
                  Staff
                </MenuItem>,
                <MenuItem key={5} value={581}>
                  Leader & Staff Ahli
                </MenuItem>
                ]
              : null}
            </TextField>
            {/* YKBUT */}
          {/* {corp === 569 ? (
            <TextField
              sx={{ textAlign: "left" }}
              variant="outlined"
              value={value2}
              onChange={(e) => (
                setValue2(e.target.value), setPos(e.target.value)
              )}
              select
              InputLabelProps={{ shrink: true }}
              label="Jabatan"
              required
              name="pos"
            >
              <MenuItem key={1} value={582}>
                Senior Instruktur
              </MenuItem>
              <MenuItem key={2} value={583}>
                Instruktur
              </MenuItem>
              <MenuItem key={3} value={580}>
                Jenior Instruktur
              </MenuItem>
              <MenuItem key={4} value={580}>
                Perawat
              </MenuItem>
              <MenuItem key={5} value={579}>
                Caregiver Daycare
              </MenuItem>
            </TextField>
          ) : showJabatan ? (
            <TextField
              sx={{ textAlign: "left" }}
              variant="outlined"
              value={value2}
              select
              InputLabelProps={{ shrink: true }}
              label="Jabatan"
              required
              name="pos"
            ></TextField>
          ) : null} */}
          {/* MBUT */}
          {/* {corp === 572 ? (
            <TextField
              sx={{ textAlign: "left" }}
              variant="outlined"
              value={value2}
              onChange={(e) => (
                setValue2(e.target.value), setPos(e.target.value)
              )}
              select
              InputLabelProps={{ shrink: true }}
              label="Jabatan"
              required
              name="pos"
            >
              <MenuItem key={1} value={580}>
                Chief Supervisor
              </MenuItem>
              <MenuItem key={2} value={580}>
                Engineering
              </MenuItem>
              <MenuItem key={3} value={580}>
                Leader Mekanik
              </MenuItem>
              <MenuItem key={4} value={580}>
                Pengawas Project
              </MenuItem>
              <MenuItem key={5} value={580}>
                Supervisor
              </MenuItem>
              <MenuItem key={6} value={580}>
                Team Leader
              </MenuItem>
              <MenuItem key={7} value={580}>
                Komandan Regu
              </MenuItem>
              <MenuItem key={8} value={580}>
                Koordinator
              </MenuItem>
              <MenuItem key={9} value={580}>
                Management Energy
              </MenuItem>
              <MenuItem key={10} value={579}>
                Adm Support
              </MenuItem>
              <MenuItem key={11} value={579}>
                Civil Construction
              </MenuItem>
              <MenuItem key={12} value={579}>
                Maintenance
              </MenuItem>
              <MenuItem key={13} value={579}>
                Services
              </MenuItem>
            </TextField>
          ) : null} */}
          {/* GSI */}
          {/* {corp === 573 ? (
            <TextField
              sx={{ textAlign: "left" }}
              variant="outlined"
              value={value2}
              onChange={(e) => (
                setValue2(e.target.value), setPos(e.target.value)
              )}
              select
              InputLabelProps={{ shrink: true }}
              label="Jabatan"
              required
              name="pos"
            >
              <MenuItem key={1} value={1357}>
                Div. Head Project Management
              </MenuItem>
              <MenuItem key={2} value={1357}>
                Dept Head Project Management
              </MenuItem>
              <MenuItem key={3} value={581}>
                Deputy Head Proj.Management
              </MenuItem>
              <MenuItem key={4} value={581}>
                Project Manager Supervisor
              </MenuItem>
              <MenuItem key={5} value={582}>
                Odoo Developer
              </MenuItem>
              <MenuItem key={6} value={582}>
                Web Developer
              </MenuItem>
              <MenuItem key={7} value={582}>
                Mobile Developer
              </MenuItem>
              <MenuItem key={8} value={582}>
                PHP Developer
              </MenuItem>
              <MenuItem key={9} value={582}>
                Business Analysis
              </MenuItem>
              <MenuItem key={10} value={582}>
                UI/UX Designer
              </MenuItem>
              <MenuItem key={11} value={583}>
                IT Helpdesk
              </MenuItem>
              <MenuItem key={12} value={583}>
                IT Support
              </MenuItem>
              <MenuItem key={13} value={580}>
                Technical Writer
              </MenuItem>
            </TextField>
          ) : null} */}
          {/* KAMAJU */}
          {/* {corp === 567 ? (
            <TextField
              sx={{ textAlign: "left" }}
              variant="outlined"
              value={value2}
              onChange={(e) => (
                setValue2(e.target.value), setPos(e.target.value)
              )}
              select
              InputLabelProps={{ shrink: true }}
              label="Jabatan"
              required
              name="pos"
            >
              <MenuItem key={1} value={583}>
                Instruktur
              </MenuItem>
              <MenuItem key={2} value={583}>
                Officer
              </MenuItem>
              <MenuItem key={3} value={580}>
                Leader
              </MenuItem>
              <MenuItem key={4} value={579}>
                Admin
              </MenuItem>
              <MenuItem key={5} value={579}>
                COP
              </MenuItem>
              <MenuItem key={6} value={579}>
                Hoseman
              </MenuItem>
              <MenuItem key={7} value={579}>
                Mekanik
              </MenuItem>
              <MenuItem key={8} value={579}>
                Tyreman
              </MenuItem>
              <MenuItem key={9} value={579}>
                Welder
              </MenuItem>
              <MenuItem key={10} value={579}>
                Painting Washing
              </MenuItem>
            </TextField>
          ) : null} */}
          {/* KOPERASI UT */}
          {/* {corp === 575 ? (
            <TextField
              sx={{ textAlign: "left" }}
              variant="outlined"
              value={value2}
              onChange={(e) => (
                setValue2(e.target.value), setPos(e.target.value)
              )}
              select
              InputLabelProps={{ shrink: true }}
              label="Jabatan"
              required
              name="pos"
            >
              <MenuItem key={1} value={580}>
                Admin Inventory & Logistic Cakung
              </MenuItem>
              <MenuItem key={2} value={580}>
                Admin Bengkel
              </MenuItem>
              <MenuItem key={3} value={580}>
                Admin Invoicing & AR Management
              </MenuItem>
              <MenuItem key={4} value={580}>
                Admin Pertashop
              </MenuItem>
              <MenuItem key={5} value={580}>
                Admin Moko
              </MenuItem>
              <MenuItem key={6} value={580}>
                Admin Invoicing K-Mart Cikarang
              </MenuItem>
              <MenuItem key={7} value={580}>
                Admin Kasir K-Mart Cikarang
              </MenuItem>
              <MenuItem key={8} value={580}>
                Mekanik Bengkel
              </MenuItem>
              <MenuItem key={9} value={580}>
                Koordinator K-Mart Cikarang
              </MenuItem>
              <MenuItem key={10} value={580}>
                Koordinator Gudang Cakung
              </MenuItem>
              <MenuItem key={11} value={580}>
                Koordinator K-Mart Cakung
              </MenuItem>
              <MenuItem key={12} value={580}>
                Koordinator General Bisnis Cakung
              </MenuItem>
              <MenuItem key={13} value={579}>
                Admin Kasir Toko K-Mart Cakung
              </MenuItem>
              <MenuItem key={14} value={579}>
                Admin Kasir Cafe Pointer
              </MenuItem>
              <MenuItem key={15} value={579}>
                Admin Pinjaman (Comercial Retail)
              </MenuItem>
              <MenuItem key={16} value={579}>
                Admin Pinjaman (Reguler)
              </MenuItem>
            </TextField>
          ) : null} */}
          {/* DPP */}
          {/* {corp === 566 ? (
            <TextField
              sx={{ textAlign: "left" }}
              variant="outlined"
              value={value2}
              onChange={(e) => (
                setValue2(e.target.value), setPos(e.target.value)
              )}
              select
              InputLabelProps={{ shrink: true }}
              label="Jabatan"
              required
              name="pos"
            >
              <MenuItem key={1} value={1357}>
                Department Head
              </MenuItem>
              <MenuItem key={2} value={1357}>
                Deputy Department Head
              </MenuItem>
              <MenuItem key={3} value={581}>
                Supervisor
              </MenuItem>
              <MenuItem key={4} value={582}>
                Staff
              </MenuItem>
              <MenuItem key={5} value={583}>
                Officer
              </MenuItem>
              <MenuItem key={6} value={580}>
                Admin Senior
              </MenuItem>
              <MenuItem key={7} value={579}>
                Admin
              </MenuItem>
            </TextField>
          ) : null} */}
          {/* LSP ABI */}
          {/* {corp === 574 ? (
            <TextField
              sx={{ textAlign: "left" }}
              variant="outlined"
              value={value2}
              onChange={(e) => (
                setValue2(e.target.value), setPos(e.target.value)
              )}
              select
              InputLabelProps={{ shrink: true }}
              label="Jabatan"
              required
              name="pos"
            >
              <MenuItem key={1} value={1357}>
                Ketua
              </MenuItem>
              <MenuItem key={2} value={581}>
                Kepala Bidang (KEIN)
              </MenuItem>
              <MenuItem key={3} value={582}>
                Wakil Kepala Bidang (KEIN)
              </MenuItem>
              <MenuItem key={4} value={583}>
                Asesor Kompetensi
              </MenuItem>
              <MenuItem key={5} value={583}>
                Asesor Adhoc
              </MenuItem>
              <MenuItem key={6} value={583}>
                Koordinator (KEIN)
              </MenuItem>
              <MenuItem key={7} value={580}>
                Administrasi (KEIN)
              </MenuItem>
              <MenuItem key={8} value={579}>
                Administrasi (KEIN) Junior
              </MenuItem>
            </TextField>
          ) : null} */}
            {errors.mobile?.type === "required" && "Mobile Number is required"}
            {errors.mobile?.type === "maxLength" && "Max Length Exceed"}
            <button className="btn" type="submit" onClick={handleClick}>
              Continue
            </button>
          </form>
        </div>
        <div className="col-2">
          <img src={bgImg} alt="" />
        </div>
        <div></div>
      </div>
    </section>
  );
};

export default FormFront;
