import LandingPage from "./LandingPage/LandingPage";
import Selection from "./LandingPage/Selection";
import SelectionCustomer from "./LandingPage/SelectionCustomer";
import SelectionInternal from "./LandingPage/SelectionInternal";
import QuestPage from "./QuestPage/QuestPage";
import Thankyoupage from "./ThankyouPage/thankyoupage";
import FormFront from "./FormPage/FormFront";
import QuestPageFront from "./QuestPage/QuestPageFront";
import Welcome from "./LandingPage/Welcome";
import Form from "./FormPage/Form";

export {
    LandingPage,
    Selection,
    SelectionCustomer,
    SelectionInternal,
    QuestPage,
    Thankyoupage,
    FormFront,
    QuestPageFront,
    Welcome,
    Form
}
