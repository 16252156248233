import React from 'react'
import { Link } from 'react-router-dom';
import "./LandingPage.css";
import { useNavigate } from 'react-router-dom'

const Welcome = () => {
    let navigate = useNavigate();
    return (
        <div className="landingpage">
            <div className='cont'>
                <div className="message">
                    <p>Yang Terhormat</p>
                    <p>Bapak/Ibu Karyawan Yayasan Karya Bakti United Tractors dan Kanitra Group</p>
                    <br />
                    <p className='dm'>Sebagai semangat perbaikan terus-menerus yang dilakukan Yayasan Karya Bakti United Tractors dan Kanitra Group, saat ini kami sedang melakukan Engagement Survey.
                        Adapun survey ini dilaksanakan untuk mengukur tingkat engagement (keterikatan), tingkat kepuasan Karyawan, serta untuk mengetahui harapan Karyawan sebagai bahan masukan untuk melaksanakan perbaikan/pengembangan di Yayasan Karya Bakti United Tractors dan Kanitra Group.
                    </p>
                    <br />
                    <p className='dm'>Demi tercapainya tujuan tersebut, kami memohon kesediaan Bapak/Ibu Karyawan Yayasan Karya Bakti United Tractors dan Kanitra Group untuk bersedia menjadi responden dalam pengisian survey.
                        Informasi dan masukan Bapak/Ibu pada survey ini bersifat pribadi dan rahasia serta hanya digunakan untuk keperluan survey.
                        Masukan yang Bapak/Ibu berikan sangat kami hargai dan tidak akan berpengaruh negatif kepada Bapak/Ibu, baik secara pribadi maupun pekerjaan.
                    </p>
                    <br />
                    <p>Selamat mengisi survey dan terima kasih atas ketersediaan Bapak/Ibu yang telah berpartisipasi.</p>
                    <br />
                    <p>Endang Tri Handajani</p>
                    <p>Ketua Yayasan Karya Bakti United Tractors</p>
                </div>
                <div className="gapBtnLanding">
                    <button className="btnLanding" onClick={() => navigate('/Selection')}>
                        Start Survey
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Welcome