import React, { useEffect, useState } from "react";
import "./questionpage.css"
import "survey-react/survey.css";
import data2 from "../../../src/data2.json";
import axios from "axios";
import { getForm } from "../../utils/useLocal";
import useFormStore from "../../store/state";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";

const QuestPageFront = () => {
  const [answer, setAnswer] = useState([]);
  const [count, setCount] = useState(0);
  const totalSteps = data2?.customers.length;
  const [form, setForm] = useState({})
  // const [position, setPosition] = useState({})
  const { name, setName } = useFormStore(state => state)
  const { nrp, setNrp } = useFormStore(state => state)
  const { date, setDate } = useFormStore(state => state)
  const { corp, setCorp } = useFormStore(state => state)
  const { date_in, setDateIn } = useFormStore(state => state)
  const { status, setStatus } = useFormStore(state => state)
  const { pos, setPos } = useFormStore(state => state)
  const [bulan, setBulan] = useState("-01-01")
  const [currentUnansweredQuestion, setCurrentUnansweredQuestion] = useState(null);
  const [question74Answered, setQuestion74Answered] = useState(false);
  const [showQuestion75, setShowQuestion75] = useState(false);

  let navigate = useNavigate();

  const handleSubmit = (evt) => {
    evt.preventDefault();
  };

  const isQuestionAnswered = (customer) => {
    const unansweredQuestions = customer.matrix_row_ids.filter((matrixRow) => {
      return !answer.some((ans) => ans.matrix_row_id === matrixRow.id);
    });
    return unansweredQuestions.length === 0;
  };

  const setFunc = (options, matrixRowId, question_id) => {
    if (answer.filter((i) => i.matrix_row_id === matrixRowId).length > 0) {
      const prevAnswer = answer.filter((i) => i.matrix_row_id !== matrixRowId)
      const newAnswer = {
        question_id: question_id,
        answer_type: "suggestion",
        matrix_row_id: matrixRowId,
        suggested_answer_id: options.id
      }
      setAnswer([...prevAnswer, newAnswer])
    } else {
      const newAnswer = {
        question_id: question_id,
        answer_type: "suggestion",
        matrix_row_id: matrixRowId,
        suggested_answer_id: options.id
      }
      setAnswer((answer) => ([...answer, newAnswer]))
    }

    if (matrixRowId === 1386) {
      if (options.value === "Ya") {
        setQuestion74Answered(true);
        setShowQuestion75(true); // Tampilkan nomor 75 jika "Ya" dipilih di nomor 74
      } else {
        setQuestion74Answered(false);
        setShowQuestion75(false); // Sembunyikan nomor 74 jika "Ya" tidak dipilih di nomor 75
      }
    }
  };

  const toggleCheckbox = (option, matrixRowId, question_id) => {
    const existingAnswerIndex = answer.findIndex(
      (i) => i.matrix_row_id === matrixRowId && i.suggested_answer_id === option.id
    );

    if (existingAnswerIndex !== -1) {
      // Jawaban sudah ada, hapus dari array jawaban
      const updatedAnswer = [...answer];
      updatedAnswer.splice(existingAnswerIndex, 1);
      setAnswer(updatedAnswer);
    } else {
      // Jawaban belum ada, tambahkan ke array jawaban
      const newAnswer = {
        question_id: question_id,
        answer_type: "suggestion",
        matrix_row_id: matrixRowId,
        suggested_answer_id: option.id,
      };
      setAnswer([...answer, newAnswer]);
    }
  };

  useEffect(() => {
    const getValue = getForm();
    const dataValue = JSON.parse(getValue)
    setForm(dataValue)
  }, [])

  const setProgressBarColor = (index) => {
    const progressBarElements = document.querySelectorAll(".progress-bar");
    progressBarElements.forEach((bar, barIndex) => {
      if (barIndex === index) {
        const isAnswered = isQuestionAnswered(data2.customers[barIndex]);
        bar.classList.remove("unanswered-progress", "complete-progress");
        if (isAnswered) {
          bar.classList.add("complete-progress");
        } else {
          bar.classList.add("unanswered-progress");
        }
      } else if (barIndex < index) {
        bar.classList.remove("unanswered-progress");
        bar.classList.add("complete-progress");
      } else {
        bar.classList.remove("unanswered-progress", "complete-progress");
      }
    });
  };

  const handleProgressBarClick = (index) => {
    setCount(index);
    setProgressBarColor(index);
  };

  const nextStep = () => {
    if (count < totalSteps - 1) {
      const unansweredQuestions = data2.customers[count].matrix_row_ids.filter((matrixRow) => {
        return !answer.some((ans) => ans.matrix_row_id === matrixRow.id);
      });
  
      if (unansweredQuestions.length === 0 || count === totalSteps - 2) {
        setCount(count + 1);
        setProgressBarColor(count + 1);
      } else {
        cekAns();
      }
    }
  };

  const prevStep = () => {
    if (count > 0) {
      setCount(count - 1);
      setProgressBarColor(count - 1);
    }
  };

  const cekAns = () => {
    const totalAnsweredQuestions = data2.customers.reduce((total, customer) => {
      return total + customer.matrix_row_ids.filter(matrixRow => {
        return answer.some(ans => ans.matrix_row_id === matrixRow.id);
      }).length;
    }, 0);
  
    // Tentukan apakah pertanyaan 74 dijawab "Ya"
    const isQuestion74Yes = answer.some(ans => ans.matrix_row_id === 1386 && ans.suggested_answer_id === "Ya");
  
    if ((totalAnsweredQuestions >= 104 && isQuestion74Yes) || totalAnsweredQuestions >= 77) {
      postData();
      console.log("DONE!!!!");
    } else {
      console.log("Jawaban Masih Kurang");
      Swal.fire({
        icon: "error",
        title: "Ada pertanyaan yang masih kosong!",
        text: "Pastikan anda sudah mengisi semua pertanyaan",
        confirmButtonColor: "#59c1bd"
      });
    }
  };

  const current = new Date();
  const datee = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;

  const postData = async () => {
    const body = JSON.stringify({
      "jsonrpc": "2.0",
      "params": {
        "survey": [
          {
            "survey_id": 4,
            "create_date": datee,
            "partner_id": 7,
            "email": "it@ykbut.co.id",
            "user_input_line_ids": [
              // {
              //   "question_id": 110,
              //   "answer_type": "char_box",
              //   "value_char_box": name
              // },
              // {
              //   "question_id": 111,
              //   "answer_type": "numerical_box",
              //   "value_numerical_box": nrp
              // },
              {
                "question_id": 112,
                "answer_type": "date",
                "value_date": date+bulan
              },
              {
                "question_id": 113,
                "answer_type": "suggestion",
                "suggested_answer_id": corp
              },
              {
                "question_id": 114,
                "answer_type": "suggestion",
                "suggested_answer_id": date_in
              },
              {
                "question_id": 115,
                "answer_type": "suggestion",
                "suggested_answer_id": status
              },
              {
                "question_id": 116,
                "answer_type": "suggestion",
                "suggested_answer_id": pos
              },
              ...answer
            ]
          }
        ]
      }
    })
    const config = {
      method: 'post',
      // url: 'http://localhost:8069/api/post/',
      url: 'https://ees.ykbut.co.id/api/post',
      // url: 'http://104.215.151.91:8010/api/post/',
      headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin':' *'
      },
      data: body
    };
    axios(config)
      .then(function (response) {
        JSON.stringify(response.data);
        navigate('/Thankyou')
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const progressBars = data2.customers.map((customer, index) => {
    const isAnswered = isQuestionAnswered(customer);
    return (
      <div
        key={index}
        className={`progress-bar ${isAnswered ? "complete-progress" : "unanswered-progress"}`}
        onClick={() => handleProgressBarClick(index)}
      >
        {/* Isi progress bar */}
      </div>
    );
  });

  return (
    <div className="questionPage">
      <p>{data2?.customers[count].title}</p>
      <div>
        <div className="">
          <form className="quest" onSubmit={handleSubmit}>
            {data2?.customers[count].matrix_row_ids.map((itemX) => {
              if (itemX.nomor === 75 && (!showQuestion75 && !question74Answered)) {
                return null; // Sembunyikan pertanyaan nomor 75 jika showQuestion75 adalah false atau question74Answered adalah false
              }
              return (
                <div className="questContainer" key={itemX.id}>
                  <div className="text">
                    <p className="number">{itemX.nomor}</p>
                    <p className="question">{itemX.value}</p>
                  </div>
                  <div>
                    <div className="option">
                      {itemX.suggested_answer_ids.map((options, idx) => (
                        <div className="Agree" key={idx}>
                          {itemX.nomor === 78 ? ( // Check if it's question number 78
                            <input
                              type="checkbox"
                              style={{ cursor: "pointer" }}
                              id="specifyColor"
                              value={options}
                              name={itemX.id}
                              checked={
                                answer !== [] &&
                                answer.some(
                                  (i) =>
                                    i.matrix_row_id === itemX.id &&
                                    i.suggested_answer_id === options.id
                                )
                              }
                              onChange={() => toggleCheckbox(options, itemX.id, data2?.customers[count].question_id)}
                            />
                          ) : (
                            <input
                              type="radio"
                              style={{ cursor: "pointer" }}
                              id="specifyColor"
                              value={options}
                              name={itemX.id}
                              checked={
                                answer !== [] &&
                                answer.some(
                                  (i) =>
                                    i.matrix_row_id === itemX.id &&
                                    i.suggested_answer_id === options.id
                                )
                              }
                              onChange={() => setFunc(options, itemX.id, data2?.customers[count].question_id)}
                            />
                          )}
                          <label
                            style={{ cursor: "pointer" }}
                            className=""
                            onClick={() =>
                              itemX.nomor === 78
                                ? toggleCheckbox(options, itemX.id, data2?.customers[count].question_id)
                                : setFunc(options, itemX.id, data2?.customers[count].question_id)
                            }
                          >
                            {options.value}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </form>
          <div className="step-indicator" style={{ margin: "5px" }}>
            {count + 1} / {totalSteps}
          </div>
          <div className="progress-bars-container">
            {progressBars}
          </div>
          <div className="gapBtn">
            <button className="button" onClick={prevStep} disabled={count === 0}>
              Back
            </button>
            {count === totalSteps - 1 ? (
              <button className="button" onClick={() => cekAns()}>
                Submit
              </button>
            ) : (
              <button className="button" onClick={nextStep}>
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestPageFront;
